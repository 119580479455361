import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Umzug = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="10 Tipps vor dem Umzug" showCalc={false} />
            <Article>
                <p>
                    Wenn der Traum immer greifbarer wird und der Umzug bald bevorsteht, kann man vor lauter Vorfreude
                    schon mal etwas vergessen. Was sollte man vor dem Umzug noch erledigen? Wir haben eine Checkliste
                    für dich zusammengestellt und geben dir ein paar Tipps, damit dein Umzug ein schönes Erlebnis wird.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Bereite deine alte Wohnung für die Übernahme vor</h2>
                <p>
                    Alle möglicherweise anstehenden Renovierungsarbeiten solltest du rechtzeitig erledigen, bzw.
                    beauftragen – sowohl in der alten als auch in der neuen Wohnung. Achte dabei darauf, wie du die
                    Wohnung laut Mietvertrag hinterlassen musst. Manchmal muss eine Mietwohnung nur besenrein
                    hinterlassen werden und der Nachmieter muss sich um eventuelle Renovierungsarbeiten selbst kümmern.
                    Manchmal musst du noch Hand anlegen. Dokumentiere immer den Zustand der alten und der neuen Wohnung
                    mit Fotos.
                </p>
                <hr />

                <h2>Mache die neue Wohnung oder dein neues Haus einzugsbereit</h2>
                <p>
                    Renovierungsarbeiten sollten auch im neuen Zuhause rechtzeitig vor dem Umzug geplant und erledigt
                    werden. Beim Einzug sollte die Elektrik überprüft und Sanitäranlagen funktionsbereit und in Ordnung
                    sein. Größere Renovierungsarbeiten können oft länger dauern als geplant, plane deshalb immer ein
                    bisschen mehr Zeit ein.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Verträge kündigen und ummelden/ Daueraufträge kündigen</h2>
                <p>
                    Mietverträge müssen rechtzeitig gekündigt werden. Oft gibt es eine Frist von mehreren Monaten, daran
                    sollte man rechtzeitig denken. Außerdem müssen Daueraufträge für Betriebs- oder Energiekosten
                    beendet werden. Am Tag vor deinem Umzug oder spätestens beim Umzug solltest du den Zählerstand
                    ablesen. Vielleicht sind auf Telefon, Internet, Radio und Fernsehen oder Kabelanschlüsse ab- oder
                    umzumelden.
                </p>
                <hr />

                <h2>Gib deine Adressänderung bekannt und melde deinen Wohnsitz um</h2>
                <p>
                    Es ist sehr wichtig vor dem Umzug, deine Adressänderung bei diversen Institutionen und
                    Organisationen bekannt zu geben. Dafür musst du noch nicht umgemeldet sein. Dazu zählen:
                </p>
                <ul>
                    <li>Arbeitgeber,</li>
                    <li>Versicherung,</li>
                    <li>Bank,</li>
                    <li>Handyanbieter, Festnetzanschluss und Internetanschluss (ummelden oder neu beantragen)</li>
                    <li>Kindergärten, Schulen,</li>
                    <li>Ämter, Behörden und Vereine (wie zB. Finanzamt oder Straßenverkehrsamt)</li>
                    <li>
                        Versicherungen (Krankenkasse, Haftpflicht, Hausrat, Kfz-Hadftpflicht, Lebensversicherung,
                        Unfallversicherung usw.)
                    </li>
                    <li>Zeitungsabos</li>
                    <li>Strom, Wasser, Gas oder Fernwärme</li>
                    <li>
                        Vergiss nicht, dein Zeitungsabo an die neue Adresse schicken zu lassen Ärzte, Steuerberater,
                        Anwälte, Mitgliedschaften (Fitnessstudio usw.)
                    </li>
                </ul>
                <p>
                    Weil es so viele Institutionen gibt und man schnell eine vergessen kann, lohnt es sich auf jeden
                    Fall ein Nachsendeantrag bei der Post. So geht nichts verloren und du kannst nach und nach alle
                    Absender über die neue Adresse informieren.
                </p>
                <p>
                    Eine An- bzw. Ummeldung des neuen Hauptwohnsitzes mittels Meldezettel muss in Österreich innerhalb
                    von drei Tagen nach Einzug erfolgen. Bei Nichterfüllung dieser Meldepflicht haben Sie mit
                    Geldstrafen von bis zu 726 Euro zu rechnen
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Beantrage einen Sonderurlaub</h2>
                <p>
                    Du kannst für deinen Umzug bei deinem Arbeitgeber einen Sonderurlaub beantragen, falls du ihn nicht
                    am Wochenende planst. Die Anzahl der Tage solltest du direkt mit deinem Arbeitgeber klären, weil es
                    hier österreichweit keine einheitlichen Regelungen gibt
                </p>
                <hr />

                <h2>Bereite den Umzug vor</h2>
                <ul>
                    <li>
                        Aufräumen und Ausmisten. Ein Umzug ist die perfekte Gelegenheit ordentlich auszumisten und viele
                        Dinge loszuwerden, damit du wieder Platz für neues hast. Wertvolle Dinge kannst du verkaufen, um
                        für deine neue Wohnung mehr Geld zu haben und Umzugskosten sind auch ziemlich hoch. Sperrmüll
                        wegbringen oder Termin dafür vereinbaren.
                    </li>
                    <li>Transporter mieten, Spedition beauftragen</li>
                    <li>Freunde rechtzeitig fragen, ob sie mithelfen können</li>
                </ul>
                <hr />

                <h2>Besorge genug Kartons und packe nicht zu früh</h2>
                <p>
                    Beschrifte alle, wirklich alle!, Kartons, damit du deine Sachen wiederfindest und nicht alles wieder
                    aufmachen musst.
                </p>
                <p>
                    Auch wenn du deine Möbel abbaust, solltest du die einzelnen Teile beschriften, damit man wieder weiß
                    wie man sie zusammenbauen soll Empfindliche Böden solltest du abdecken um sie beim Umzug zu
                    schützen. Müllbeutel und Reinigungsmittel besorgen. Werkzeugkiste nicht vergessen, du wirst sie
                    brauchen.
                </p>
                <p>
                    Die wichtigsten Sachen, die du zum Wohlfühlen brauchst, solltest du extra packen. Zahnbürste,
                    Kissen, Wertgegenstände, wichtige Unterlagen sollten an einem Ort sein. Kleidung für den nächsten
                    tag und ein Outfit für den tag danach. Verbandkasten bereithalten.
                </p>
                <p>
                    <strong>Nicht vergessen:</strong> Klingel und Türschilder abnehmen
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Leiste dir professionelle Helfer</h2>
                <p>
                    Irgendwann haben es auch die besten Freunde satt, Schränke vom 4. Stock in den 3. Stock der neuen
                    Wohnung zu tragen. Proefssionelle Umzugshelfer beschweren sich nicht, sagen nicht in letzter Minute
                    ab und sind eine enorme Hilfe. …
                </p>
                <h3>
                    Sei bereit auf bestellte Möbel und Anschlüsse zu warten. - Internetanmeldung: Wie bekomme ich dieses
                    WLAN in meine Wohnung?
                </h3>
                <p>
                    Lieferzeiten können lange dauern, auf einen Telefon- oder Internetanschluss kann man ebenso
                    wochenlang warten. Je früher du also an diese Dinge denkst, desto schneller kannst du dich in deinem
                    neuen zuhause so richtig wohlfühlen.
                </p>
                <h3>Von der Steuer absetzen: Rechnungen sammeln</h3>
                <p>
                    Möglicherweise kannst du dir Umzugskosten von der Steuer absetzen lassen. Wenn ja, sammle unbedingt
                    alle Rechnungen der Spedition, Belege für Benzinkosten, Verpackungsmaterial und Werkzeug,
                    Renovierungskosten, Maklergebühren usw.
                </p>
                <h3>Damit der Umzugstag nicht zur Qual wird</h3>
                <p>
                    Am Umzugstag solltest du Snacks für dich und alle Helfer*innen haben. Falls du an Sonntagen
                    umziehst, solltest du schon vor dem Umzug daran denken.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"umzug"}></BreadcrumbList>
            <ArticleStructuredData page={"umzug"} heading={"10 Tipps vor dem Umzug"} />
        </Layout>
    );
};

export default Umzug;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.umzug", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
